.add-edit-business-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .business-form label {
    display: block;
    margin: 10px 0 5px;
    font-weight: bold;
  }
  
  .business-form input,
  .business-form textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .business-form button {
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .business-form button:hover {
    background-color: #2980b9;
  }
  select {
    color: black; /* Text color for the selected value */
    background-color: white; /* Background color for the dropdown */
  }
  
  select option {
    color: black; /* Text color for options */
    background-color: white; /* Background color for options */
  }
  .business-form select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-family: Arial, sans-serif;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    color: #333;
    appearance: none; /* Remove default browser styling */
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='5' viewBox='0 0 10 5'%3e%3cpath fill='%23999' d='M0 0l5 5 5-5z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px;
    cursor: pointer;
  }
  
  /* On focus */
  .business-form select:focus {
    outline: none;
    border: 1px solid #007bff;
    background-color: #fff;
  }
  
  /* Hover effect */
  .business-form select:hover {
    border-color: #007bff;
  }
  
  /* Option styling */
  .business-form option {
    font-size: 16px;
    color: #333;
    padding: 5px;
  }
  
  /* Error styling */
  .business-form select.error {
    border-color: #ff4d4d;
    background-color: #ffe6e6;
  }