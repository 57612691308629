.more-mentors-container {
  text-align: center;
}

h2 {
  font-size: 36px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 20px;
}

.mentor-cards {
  display: flex;
  flex-direction: column; 
  gap: 20px; 
  padding: 20px;
  align-items: center; 
}

.mentor-card {
  display: flex;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 1000px
}

.mentor-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
}

.mentor-card-header {
  padding: 8px;
  width: 200px;
}

.mentor-card-img {
  width: 120px; 
  height: 120px; 
  border-radius: 50%; 
  object-fit: cover; 
  border: 1px solid #00e1ff;
  margin-top: 22px;
}

.mentor-card-info {
  text-align: center;
  padding: 8px;
  border-radius: 15px 0px 0px 15px ;
  background-color: #00e1ffb1;
  width: 800px;
}

.mentor-card-info h3 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.mentor-card-info p {
  font-size: 14px;
  color: #555;
  margin-bottom: 8px;
}

.view-profile-btn {
  font-size: 14px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100px;
  background-color: #eaf4fb;
  color: black;
}
  
.book-now-btn {
  background-color: #007bff;
  color: #fff;
}

.view-profile-btn:hover {
  background-color: #d7eaf6;
}

.book-now-btn:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .mentor-card {
    width: 100%; 
  }

  .mentor-card-header {
    padding: 2px;
    width: 60px;
  }
  
  .mentor-card-img {
    width: 50px; 
    height: 50px; 
    margin-top: 55px;
  }

  .view-profile-btn,
  .book-now-btn {
    width: 100%;
  }
}


  
  