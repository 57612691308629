/* BusinessPage.css */

/* Overall container styling */
.business-container {
    margin: 20px auto;
    padding: 20px;
    max-width: 800px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  /* Heading styling */
  .business-container h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 15px;
    text-align: center;
    font-weight: bold;
  }
  
  /* Business details container */
  .business-details {
    margin-top: 20px;
    padding: 15px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #ddd;
  }
  
  /* Individual business detail */
  .business-details p {
    margin: 8px 0;
    color: #555;
    font-size: 16px;
  }
  
  .business-details p strong {
    color: #3498db;
    font-weight: 600;
  }
  
  /* Button styling */
  .edit-button {
    margin-top: 20px;
    padding: 10px 15px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    display: block;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    transition: background-color 0.3s ease;
  }
  
  .edit-button:hover {
    background-color: #2874a6;
  }
  
  /* Link styling for website */
  .business-details a {
    color: #3498db;
    text-decoration: none;
  }
  
  .business-details a:hover {
    text-decoration: underline;
  }
  
  /* No business message */
  .business-container p {
    text-align: center;
    color: #777;
    font-style: italic;
    margin-top: 20px;
  }
  .business-page-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .add-product-button {
    display: block;
    margin: 0 auto 20px auto;
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .product-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  /* .product-card {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #f9f9f9;
  }
  
  .product-card h3 {
    margin-bottom: 10px;
  } */
  
  .edit-button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #3498db;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
  }
  
  .modal-content label {
    display: block;
    margin-bottom: 10px;
  }
  
  .modal-actions {
    margin-top: 15px;
    text-align: right;
  }
  
  .save-button {
    padding: 5px 10px;
    background-color: #3498db;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .cancel-button {
    padding: 5px 10px;
    background-color: #e74c3c;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 10px;
  }
  .modal-content select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-family: Arial, sans-serif;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    color: #333;
    appearance: none; /* Remove default browser styling */
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='5' viewBox='0 0 10 5'%3e%3cpath fill='%23999' d='M0 0l5 5 5-5z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px;
    cursor: pointer;
  }
  textarea{
    width: 100%;
  }
  .banner-section {
    position: relative;
    width: 100%;
  }
  
  .banner-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    cursor: pointer;
  }
  
  .business-picture-section {
    position: relative;
    margin-top: -75px;
    display: inline-block;
  }
  
  .business-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid white;
    cursor: pointer;
  }
  
  .business-details {
    margin-top: 20px;
  }
  .product-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    background-color: #fff;
  }
  
  .product-card img {
    max-width: 100%;
    height: auto;
    max-height: 150px;
    border-radius: 8px;
    margin-bottom: 12px;
  }
  
  .product-card h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 8px;
    text-align: center;
  }
  
  .product-card p {
    font-size: 14px;
    color: #666;
    margin-bottom: 4px;
    text-align: center;
  }
  
  .product-card button {
    padding: 8px 12px;
    margin-top: 12px;
    border: none;
    background-color: #3498db;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .product-card button:hover {
    background-color: #2a7bbd;
  }
  
  .product-card .edit-product-button,
  .product-card .upload-image-button {
    width: 100%;
    margin-bottom: 8px;
  }
  .modal-content .checkbox-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .modal-content input[type="checkbox"] {
    width: 15px;
    height: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }
  
 
  .modal-content label {
    font-size: 16px;
    color: #333;
    cursor: pointer;
    margin-left: 8px;
    vertical-align: middle;
  }
  
  