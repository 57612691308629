body {
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
}

.profile-container {
  max-width: 400px;
  margin: 50px auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

/* .edit-picture {
  display: block;
  color: #3498db;
  font-size: 14px;
  margin-top: 8px;
  cursor: pointer;
  text-decoration: none;
}

.edit-picture:hover {
  text-decoration: underline;
} */

.profile-name {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
}

.profile-job {
  font-size: 16px;
  color: #777;
  margin-bottom: 15px;
}

.icon-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.icon-button svg {
  fill: #ffffff;
  width: 20px;
  height: 20px;
}

.profile-details {
  margin: 15px 0;
  text-align: center;
  color: #555;
  font-size: 14px;
  line-height: 1.5;
}

.edit-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #3498db;
  color: #ffffff;
  font-size: 16px;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-button:hover {
  background-color: #2874a6;
}
.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 15px auto;
  border: 4px solid #d9d9d9;
}

.profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
}

.icon-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color:#3498db ;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.icon-button i {
  color: white;
  font-size: 18px;
}

.icon-button:hover {
  transform: scale(1.1);
}
.business-button{
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #3498db;
  color: #ffffff;
  font-size: 16px;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.profile-img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #ddd;
}

.file-upload-label {
  cursor: pointer;
}

.upload-button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.upload-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}


  