.section-one {
    text-align: center;
    padding: 50px 0px;
    background-image: url('https://imgs.search.brave.com/hOKmbNOXRbSwSWhWfVGMCAul5vjaQYrr7N4ONcjX0vM/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9jZG4u/cGl4YWJheS5jb20v/cGhvdG8vMjAxOC8w/OC8xNi8xMS8zNi9t/ZW4tMzYxMDI1NV8x/MjgwLmpwZw');
    background-size: cover;
    background-position: center;
    height: 160px;
    color: #ffffff;
  }
  
.carousel-container {
  margin: 20px auto; 
  padding: 20px;
}

.carousel-card {
  padding: 15px; 
  transition: transform 0.3s;
}

.carousel-card:hover {
  transform: scale(1.05); 
}

.card-content {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  transition: box-shadow 0.3s ease-in-out;
  height: 350px; 
  width: 250px; 
  margin: auto; 
}

.card-content:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
}

.card-img {
  width: 100%;
  height: 200px; 
  object-fit: cover;
  border-radius: 10px 10px 0 0; 
}

.card-info {
  padding: 10px;
}

.card-info h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0;
}

.card-info p {
  font-size: 14px;
  color: #777;
  margin: 5px 0;
}

.view {
  text-align: right;
  margin: 20px;
}

.view button {
  padding: 10px 20px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.view button:hover {
  background-color: #1d6fa5;
}
.search-bar {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  box-sizing: border-box;
}

.section-three{
  background-color: rgba(196, 196, 196, 0.552);
  padding-top: 5px;
  padding-bottom: 4px;
}
.card-content-b {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  transition: box-shadow 0.3s ease-in-out;
  height: 120px; 
  width: 250px; 
  margin: auto; 
}

.card-content-b:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
}

.search-input {
  width: 300px;
  padding: 8px 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.search-button {
  margin-left: 10px;
  margin-top: 4px;
  padding: 8px 10px ;
  font-size: 16px;
  color: white;
  background-color: #3498db;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #2874a6;
}


