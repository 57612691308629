.more-mentors-container {
    text-align: center;
  }
  
  h2 {
    font-size: 36px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 20px;
  }
  
  .mentor-cards {
    display: flex;
    flex-direction: column; 
    gap: 20px; 
    padding: 20px;
    align-items: center; 
  }
  
  .mentor-card {
    display: flex;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 80%;
    
  }
  
  .mentor-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
  }
  
  .mentor-card-info {
    text-align: center;
    padding: 10px;
    border-radius: 15px 0px 0px 15px ;
    background-color: #00e1ffb1;
    width: 100%;
  }
  
  .mentor-card-info h3 {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
  }
  
  .mentor-card-info p {
    font-size: 14px;
    color: #555;
    margin-bottom: 8px;
  }
  
  @media (max-width: 768px) {
    .mentor-card {
      width: 100%; 
    }
  
    .mentor-card-footer {
      flex-direction: column;
      gap: 10px;
    }
  
    .view-profile-btn,
    .book-now-btn {
      width: 100%;
    }
  }