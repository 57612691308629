/* General Container Styling */
.plans-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
    gap: 20px;
    padding: 20px;
  }
  
  /* Plan Card Styling */
  .plan-card {
    border: 2px solid #007bff; /* Blue border */
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .plan-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  /* Title and Description */
  .plan-card h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .plan-card h4 {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
  }
  
  /* Features List */
  .features-list {
    list-style-type: none; /* Remove default bullets */
    padding: 0;
    margin: 0;
    text-align: left;
  }
  
  .features-list li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 10px;
    font-size: 0.95rem;
    color: #333;
  }
  
  .features-list li::before {
    content: "✔"; /* Tick mark */
    position: absolute;
    left: 0;
    color: #007bff;
    font-size: 1rem;
    line-height: 1;
  }
  
  /* Button Styling */
  .choose-plan-btn {
    margin-top: 15px;
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .choose-plan-btn:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .plan-card h3 {
      font-size: 1.2rem;
    }
  
    .plan-card h4 {
      font-size: 0.9rem;
    }
  
    .features-list li {
      font-size: 0.85rem;
    }
  
    .choose-plan-btn {
      font-size: 0.9rem;
      padding: 8px 15px;
    }
  }
  
  @media (max-width: 480px) {
    .plan-card {
      padding: 15px;
    }
  
    .plan-card h3 {
      font-size: 1rem;
    }
  
    .features-list li {
      font-size: 0.8rem;
    }
}  
  