.body1{
    background-image: url('https://wallpaperaccess.com/full/1741723.png');
    width: 100%;
    height: 100vh; 
    background-size: cover;
    background-position: center;
}
.login-container{
    display: grid;
    place-items: center;
    height: 100vh;
}
.login{
    background-color: rgba(255, 255, 255, 0.877);
    height: 400px;
    width: 300px;
    text-align: center;
    padding: 0px 20px 10px 20px;
    border-radius: 2%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.login h2{
    font-style: italic;
}
.login p{
    font-size: small;
}
.btn-g{
    background-color: transparent;
    border-radius: 20px;
    padding: 6px 10px 6px 10px;
    border: solid 1px gray ;
}
.login img{
    height: 17px;
    width: 17px;
    margin-top: 20px;
}
.btn-s {
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    margin-bottom: 10px;
}
  
.btn-s:hover {
    background-color: #1d6fa5;
}
input{
    border: 1px solid #818181;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
}
.username, .pass1{
    text-align: left;
    padding: 5px 0px 5px 0px;
}
.pass1{
    padding-bottom: 30px;
    position: relative;
}
.toggle{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 18px;
    color: #3498db;
}
label{
    color: grey;
    font-size: small;
}
.reg{
    font-size: small;
    font-style: oblique;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    padding: 6px;
    margin-top: 15px;
}
.reg:hover {
    background-color: #1d6fa5;
    color: #fff;
}
