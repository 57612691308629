.user-details-container {
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
  }
  
  .user-details-container h2,
  .user-details-container h3 {
    text-align: center;
    margin-bottom: 20px;
    color: #2c3e50;
    font-size: 24px;
    font-weight: bold;
  }
  
  .user-details {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    gap: 20px;
  }
  
  .user-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #3498db;
  }
  
  .user-info {
    flex: 1;
    font-size: 16px;
    line-height: 1.5;
  }
  
  .user-info p {
    margin: 5px 0;
    color: #34495e;
  }
  
  .user-info strong {
    color: #2c3e50;
  }
  
  .business-details {
    padding: 15px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .business-details p {
    margin: 10px 0;
    font-size: 16px;
    color: #34495e;
  }
  
  .business-details strong {
    color: #2c3e50;
  }
  
  .error-message,
  .loading-message {
    text-align: center;
    margin-top: 20px;
    color: #e74c3c;
    font-size: 18px;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .user-details {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .user-info {
      text-align: center;
    }
  
    .user-image {
      width: 100px;
      height: 100px;
    }
  }
  